.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  width: 15%;
  min-width: 200px;
  height: 100vh;
  padding: 1rem;
  background-color: #012041;
  box-sizing: border-box;
  /* overflow-y: auto;*/
  display: flex;
  flex-direction: column;
  color: #fff;
}

.logout-button {
  width: 100%;
  padding: 0.75rem;
  border: none;
  border-radius: 5px;
  background-color: #dc3545;
  color: white;
  font-size: 1rem;
  cursor: pointer;
  box-sizing: border-box;
  transition: background-color 0.3s ease;
  display:flex;
  justify-content: space-around;
  align-items: center;
}

.logout-button:hover {
  background-color: #c82333;
}

.logout-button p {
  margin: 0;
}


.login-area {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  padding: 1rem;
  border-bottom: 1px solid #bbb;
}

.sidebar-avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 1rem;
}

.user-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start
}

.user-name, .user-surname {
  font-size: 1rem;
  font-weight: bold;
  margin: 0; /* Remove default margin */
}

.menu {
  list-style: none;
  padding: 0;
  margin: 0;
  flex-grow: 1; /* Allow the menu to grow and push settings to the bottom */
}

.menu-item {
  padding: 0.75rem 1rem;
  font-size: 1rem;
  color: #fff; /* White text color */
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  display: flex;
  align-items: center;
  border-radius: 5px;
  margin-bottom: 0.5rem;
  border-left: 5px solid transparent;
  font-weight: normal;
}

.menu-item:hover,
.menu-item.active {
  background-color: #0c3867; /* Blue background on hover/active */
  color: white;
  border-left: 5px solid #dc3545;
}

.menu-item.selected,
.submenu-item.selected {
  font-weight: bold;
  background-color: #0c3867; /* Blue background on hover/active */
}

.menu-icon {
  margin-right: 0.5rem;
}

.submenu-icon {
  margin-left: auto;
}

.submenu {
  list-style: none;
  padding: 0;
}

.submenu-item {
  padding: 0.5rem 0.3rem;
  color: #fff; /* White text color */
  cursor: pointer;
  display: flex;
  font-size: 0.95em;
  align-items: center;
  justify-content: space-between; /* Add this to space out the delete icon */
  margin-bottom: 0.5rem;
  position: relative; /* Add this for positioning the delete icon */
}

.submenu-item span {
  white-space: nowrap; /* Prevents text from wrapping to the next line */
  overflow: hidden; /* Hides the overflow text */
  text-overflow: ellipsis; /* Adds the ... */
  max-width: 200px; /* Adjust the width based on your layout */
  display: inline-block;
}

.submenu-item:hover,
.submenu-item.active {
  background-color: #0c3867; /* Blue background on hover/active */
  color: white;
  font-weight: bold;
}

.delete-icon {
  margin-left: 0.5rem;
  cursor: pointer;
  color: #dc3545; /* Red color for delete icon */
  opacity: 0; /* Hide the icon by default */
  transition: opacity 0.3s ease; /* Smooth transition for showing/hiding */
}

.submenu-item:hover .delete-icon {
  opacity: 1; /* Show the icon on hover */
}

.results-container {
  max-height: 500px;
  overflow-y: auto;
  margin-left: 1rem;
}

.results-category {
  font-size: 1rem;
  font-weight: bold;
  margin-top: 1rem;
}

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}



/* Media Query for screens smaller than 1200px */
@media (max-width: 1200px) {
  .sidebar {
    position: absolute;
    width: 100%;
    height: auto;
    min-height: 100px;
    flex-direction: row;
    align-items: center;
    /* overflow-y: auto; */
    align-items: center;
    justify-content: space-between;
    transition: all 0.3s ease;
    z-index: 999;
  }

  .sidebar .login-area {
    flex-direction: row;
    align-items: center;
    padding: 0.5rem;
    border-bottom: none;
    border-right: 2px solid #bbb;
    margin-bottom: 0;
    margin-right: 1rem;
  }

  .sidebar-avatar {
    width: 40px;
    height: 40px;
  }

  .menu {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-grow: 1;
    justify-content: space-evenly
  }

  .menu-item {
    position: relative;
    padding: 0.5rem;
    margin-bottom: 0;
    font-size:1rem;
    cursor: pointer;
  }

  .submenu {
    /* top: 100%; */
    background-color: #012041;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1000; /* Ensure submenu is on top */
    flex-direction: column;
    /* width: 200px;  */
    border-radius: 5px;
  }

  .menu-item:hover .submenu, .menu-item:focus .submenu, .menu-item.active .submenu {
    display: flex;
  }

  .submenu-item {
    padding: 0.5rem;
    font-size: 0.875rem;
    color: #fff;
    cursor: pointer;
  }

  .submenu-item:hover, .submenu-item.active {
    background-color: #0c3867;
    font-weight: bold;
  }

  .results-container {
    /* display: none; */
    position: absolute;
    top: 100%;
    background-color: #012041;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1000; /* Ensure results-container is on top */
    padding: 1rem;
    border-radius: 5px;
    overflow-y: none;
    /* overflow-y: auto; */
  }

  .results-category {
    margin-top: 0.5rem;
  }

  .logout-button {
    width: 150px;
    padding: 0.75rem;
    color: white;
    font-size: 1rem;
  }
}

@media (max-width: 800px) {
  .menu-item {
    position: relative;
    padding: 0.4rem;
    margin-bottom: 0;
    font-size:0.8rem;
    cursor: pointer;

  }

  .submenu-item {
    padding: 0.5rem;
    font-size: 0.7rem;
    color: #fff;
    cursor: pointer;
  }

  .logout-button {
    width: 150px;
    padding: 0.6rem;
    color: white;
    font-size: 0.8rem;
  }

  .login-area{
    display: none;
  }
}

@media (max-width: 480px) {
  .logout-button p {
    display: none;
  }
  .logout-button{
    width: 40px;
  }
  .logout-button svg {
    height: 15px;
  }
}
