.expert-card {
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 10px;
  margin: 10px;
  width: 100%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
  display: flex;
  align-items: center;
  border-left: 4px solid #dc3545;
  border-bottom: 1px solid #dc3545;
}

.avatar-container {
  flex: 1;
  max-width: 20%;
}

.avatar {
  width: 100%;
  border-radius: 50%;
}

.info-container {
  flex: 4;
  padding-left: 15px;
}

.row-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
  margin: 4px 0;
  width: 100%;
  font-size: 0.8em;
}

.name {
  font-size: 1.3em;
  font-weight: bold;
  color: #0c3867;
}

.stars{
  margin-left: 10px;
  margin-right: 10px;
}

.stars .star {
  font-size: 1em;
  color: lightgray;
}

.stars .star.filled {
  color: rgb(255, 153, 0);
}

.city, .zipcode, .phone, .email {
  font-size: 1.1em;
  margin-left: 7px;
  margin-right: 7px;
}

.phone i, .email i {
  margin-right: 5px;
}

.expertise{
  font-size: 1em;
  color:#0c3867;
}

@media (max-width: 600px) {
  .expert-card {
    flex-direction: column;
    align-items: flex-start;
  }

  .avatar-container {
    max-width: 100%;
    text-align: center;
  }

  .info-container {
    padding-left: 0;
  }
}
