.terms-container {
    padding: 20px;
    max-width: 800px;
    margin: 160px auto;
    font-family: Arial, sans-serif;
    line-height: 1.6;
  }

.terms-container h1, .terms-container h2, .terms-container  h3 {
    color: #154d89;
  }

  .terms-container ul {
    list-style-type: disc;
    margin-left: 20px;
  }

  .terms-container p, .terms-container ul {
    margin-bottom: 40px;
  }
