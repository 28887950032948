.menu-bar {
  display: flex;
  justify-content: flex-start;
  margin: 30px;
  border-bottom: 2px solid #154d89;
}

.menu-bar button {
  background: white;
  border: 2px solid #154d89;
  color: #154d89;
  margin: 8px;
  padding: 15px 80px;
  font-weight: bold;
  cursor: pointer;
  border-radius: 10px;
}

.menu-bar button.active, .menu-bar button.hover{
  background-color: #154d89;
  color: white;
}

.profile-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 75vh;
  background-color: #f4f4f4;
}

.profile-section, .my-payment-section {
  margin-top: 20px;
}

.my-payment-section h2{
  margin: 30px;
}

.validation-status {
  margin-left: 10px;
}

.validation-status.validated {
  color: green;
}

.validation-status.not-validated {
  color: red;
}

.plans {
  justify-content: space-around;
  flex-direction: row;
  align-content: center;
  flex-wrap: wrap;
}

.plan {
  border: 1px solid #ccc;
  padding: 20px;
  width: 30%;
  text-align: center;
  border-radius: 4px;
}

.plan.active-plan {
  border-color: #007bff;
  background-color: #e6f7ff;
}

.current-plan {
  color: #007bff;
  font-weight: bold;
}
