.card-detail-container {
  padding: 1rem;
  width: 100%;
}

.back-button {
  padding: 0.5rem 1rem;
  margin-bottom: 1rem;
  background-color: #012041;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.back-button:hover {
  background-color: #154d89;
}

.card-detail {
  display: flex;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.card-detail-images {
  flex: 1;
  background-color: #f3f3f3;
}

.card-detail-image {
  display: flex !important;
  justify-content: center;
  height: 600px;
  background-color: #f3f3f3;
}

.card-detail-image img {
  max-height: 100%;
  max-width: 100%;
  object-fit: cover;
}

.card-detail-info {
  flex: 1;
  padding: 1rem;
  overflow-y: auto;
}

.card-detail-info h2 {
  margin-top: 0;
  font-size: 1.5rem;
}

.card-detail-info p {
  margin: 0.5rem 0;
  font-size: 1rem;
}

.card-link-detail {
  display: block;
  margin-top: 0.5rem;
  font-size: 0.9rem;
  color: #007bff;
  text-decoration: none;
}

.card-link-detail:hover {
  text-decoration: underline;
}

/* Carousel Custom CSS */
.carousel-control-prev-icon,
.carousel-control-next-icon {
  background-color: #012041; /* Change the arrow background color */
  width: 50px;  /* Increase the width */
  height: 50px; /* Increase the height */
  border-radius: 50%; /* Make the arrows circular */
}

.carousel-control-prev-icon:after,
.carousel-control-next-icon:after {
  font-size: 30px; /* Increase the size of the arrow icon */
}

.carousel-control-prev,
.carousel-control-next {
  width: 50px; /* Increase the clickable area */
}

.carousel-indicators li {
  background-color: #012041; /* Change the indicator color */
  width: 12px;
  height: 12px;
  border-radius: 50%; /* Make the indicators circular */
}

.carousel-indicators .active {
  background-color: #012041; /* Ensure the active indicator is also the same color */
}



.card-detail-placeholder {
  width: 100%;
  height: auto; /* Adjust as needed */
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #cbcbcb;
  color: white;
  font-size: 1.5rem;
  border-radius: 10px; /* Match the border radius of .card-image if any */
}
