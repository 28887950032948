.product-container {
  width: 65%;
  margin: 0 auto;
  text-align: center;
  margin-top: 100px;
  margin-bottom: 100px;
}

.product-container h1 {
  font-size: 3em;
  margin-top: 30px;
  margin-bottom: 30px;
  text-align: center; /* Center the title */
  color: #154d89;
}

.product-container p {
  font-size: 1.6em;
}

.problematic-area {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  margin-top: 40px;
  margin-bottom: 20px;
}

.problematic-area.reverse {
  flex-direction: row-reverse;
}

.problematic-image {
  width: 45%;
  height: auto;
  margin: auto;
  display: block;
  align-items: center;
  justify-content: center;
}

.problematic-image video,
.problematic-image img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the video or image covers the container */
}


.problematic-text {
  max-width: 55%;
  padding-left: 50px;
  padding-right: 50px;
}

.problematic-text h2 {
  font-size: 2.5em;
  margin-bottom: 40px;
  text-align: center; /* Center the title */
}

.problematic-text p {
  font-size: 1.2em;
  margin: 5px;
  text-align: left; /* Center the subtitle */
}

.pain-solution-container {
  display: flex;
  justify-content: space-between;
}

.pain-points,
.solutions {
  width: 75%;
  align-content: center;
  margin: auto;
}

.pain-points {
  margin-right: 20px;
}

.pain,
.solution {
  display: flex;
  align-items: center;
  min-height: 50px;
}

.pain p,
.solution p {
  margin-left: 10px;
  font-size: 1.1em;
  text-align: left;
  min-width: 120px;
}

.faicon {
  color: #154d89;
  font-size: 2em; /* Larger icon size */
  padding-right: 10px;
  opacity: 66%;
}

.contentainer-product-button {
  display: flex;
  justify-content: center;
  font-size: 1.1em;
}

.button-arrow {
  margin-left: 10px;
  font-size: 1.5em;
}

.delimiter {
  border: 1px solid #ddd;
  margin: 40px 0;
}

.bubble {
  position: relative;
  display: inline-block;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1em;
  font-weight: bold;
  margin-right: 10px;
  min-width: 80px;
  min-height: 80px;
  margin: 7px;
  font-size: 1.4em;
  background: linear-gradient(235deg, white 20%, #154d89 20%, #154d89 100%);
  border-radius: 50%;
}

/* Media Queries for responsive design */
@media (max-width: 1200px) {
  .product-container {
    width: 90%;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .product-container h1 {
    font-size: 2.5em;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .product-container p {
    font-size: 1.2em;
    margin-bottom: 30px;
  }

  .problematic-area {
    flex-direction: column;
    margin-top: 60px;
    margin-bottom: 60px;
    width: 70%;
    margin: 0 auto;
  }

  .problematic-area.reverse{
    flex-direction: column;
    margin-top: 60px;
    margin-bottom: 60px;
  }

  .problematic-text {
    max-width: 100%;
    padding: 0;
  }

  .problematic-image{
    height: 100%;
  }

  .problematic-text {
    padding-left: 20px;
    padding-right: 20px;
  }

  .problematic-text h2 {
    font-size: 2em;
    margin-bottom: 20px;
    padding-top: 20px;
    padding-bottom: 10px;
  }

  .problematic-text p {
    font-size: 1em;
    margin-bottom: 20px;
  }

  .pain-solution-container {
    flex-direction: column;
    align-items: center;
  }

  .pain-points {
    margin-bottom: 20px;
  }

  .solution {
    display: flex;
    align-items: flex-start;
    margin-bottom: 10px;
  }

  .pain{
    display:none;
  }
}

@media (max-width: 600px) {
  .product-container h1 {
    font-size: 2em;
  }

  .problematic-text h2 {
    font-size: 1.5em;
  }

  .problematic-text p {
    font-size: 0.9em;
  }

  .pain p, .solution p {
    font-size: 1em;
    max-width: 200px;
  }

  .pain-solution-container {
    flex-direction: column;
    align-items: center;
  }

  .solution {
    display: flex;
    align-items: flex-start;
    margin-bottom: 10px;
  }

  .pain{
    display:none;
  }
}


@media (max-width: 480px) {
.pain-solution-container{
  display: none;
}
}
