.firm-presentation-plateforme {
  font-family: "Arial", sans-serif;
}

.firm-presentation-plateforme header {
  border-bottom: 1px solid #ddd;
  margin-bottom: 15px;
}

.navbar-scrolled-plateforme {
  background: #fff;
  padding: .5rem 1rem;
  align-items: center;
  justify-content: space-between;
  position:relative;
  top: 0;
  left: 0;
  z-index: 998; /* Ensure it stays below the sidebar */
}

.logo-container-plateforme {
  display: flex;
  align-items: center;
}

.logo-plateforme {
  margin-left: 45px;
}

.logo-plateforme img {
  width: 70px;
  height: auto;
  transition: width 0.3s, transform 0.3s;
}

.company-name-plateforme {
  margin-left: 10px;
  font-size: 1.5em;
  font-weight: bold;
  color: #154d89;
  transition: font-size 0.3s;
}

.menu-container-plateforme {
  display: flex;
  align-items: center;
  margin-right: 25px;
}

.language-container {
  display: flex;
  align-items: center;
  margin-left: 20px;
  margin-right: 10px;
}

.menu-item-plateforme, .account-button-plateforme {
  background: white;
  border: 2px solid #154d89;
  padding: 8px 18px;
  font-weight: bold;
  cursor: pointer;
  border-radius: 10px;
  margin-left: 10px;
  min-width: 145px;
}

.lang-button {
  border: none;
  display: flex;
  align-items: center;
  padding: 8px 12px;
}

.flag-icon {
  width: 20px;
  height: 15px;
  margin-right: 8px;
}

.menu-item-plateforme:hover, .account-button-plateforme:hover {
  background: #154d89;
  color: white;
}

.lang-button:hover {
  background: #eaeaea;
  color: #154d89;
}


/* Media Queries for responsive design */
@media (max-width: 1000px) {
  header {
    flex-direction: column;
    align-items: flex-start;
    height: auto;
    padding: 10px;
  }

  .navbar {
    height: auto;
  }

  .logo-container {
    justify-content: space-between;
    width: 100%;
  }

  .nav-links,
  .menu-container-plateforme {
    flex-direction: column;
    align-items: flex-start;
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    align-content: center;
  }


  .nav-links a {
    font-size: 1.2em;
  }

  .account-button {
    margin-right: 0;
  }

  .menu-toggle {
    display: block;
    padding-right: 30px;
    font-size: 2em;
  }

  .show-menu .menu-content {
    display: flex;
    width: 100%;
    align-items: center;
    padding: 30px;
  }
}

/* Media Queries for responsive design */
@media (max-width: 600px) {
  header {
    flex-direction: column;
    align-items: flex-start;
    height: auto;
    padding: 10px;
  }

  .logo-container {
    justify-content: space-between;
    width: 100%;
  }

  .nav-links,
  .menu-container-plateforme {
    flex-direction: column;
    align-items: center;
    display: flex;
    gap: 20px;
    flex-wrap: nowrap;
    gap: 20px;
  }

  .nav-links a {
    font-size: 1em;
  }

  .account-button {
    margin-right: 0;
    font-size: 1em;
  }

  .menu-toggle {
    display: block;
    padding-right: 30px;
    font-size: 2em;
  }

  .show-menu .menu-content {
    display: flex;
    width: 100%;
    align-items: center;
    flex-direction: column;
    padding: 20px;
  }

  .company-name {
    display: none;
  }

  .logo img {
    width: 85px;
    height: auto;
  }
}

@media (max-width: 380px) {
  header {
    flex-direction: column;
    align-items: flex-start;
    height: auto;
    padding: 10px;
  }

  .logo-container {
    justify-content: space-between;
  }

  .logo {
    margin-left: 5px;
  }

  .nav-links,
  .menu-container-plateforme {
    flex-direction: column;
    align-items: center;
    display: flex;
    gap: 20px;
    flex-wrap: nowrap;
    gap: 20px;
  }

  .nav-links a {
    font-size: 0.9em;
  }

  .account-button {
    margin-right: 0;
    font-size: 0.9em;
    width: 140px;
  }

  .menu-toggle {
    display: block;
    padding-right: 30px;
    font-size: 2em;
  }

  .show-menu .menu-content {
    display: flex;
    width: 100%;
    padding: 10px;
  }

  .company-name {
    display: none;
  }

  .logo img {
    width: 70px;
    height: auto;
}
}
