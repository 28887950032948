.search-area {
  width: 70%; /* 50% of the container width */
  /* height: 65px; Fixed height */
  background: #f7f9fc;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(226, 191, 191, 0.1);
  display: flex;
  align-items: center;
  position: relative;
  margin-top: 20px;
  margin-right: auto;
  margin-bottom: 40px;
  margin-left: auto;
  flex-direction: column; /* Stack items vertically */
  /* padding-bottom: 1rem; Added padding to accommodate error message */
}

.search-form {
  display: flex;
  width: 100%;
  height: 100%; /* Ensure the form takes the full height */
  height: 65px; /* Fixed height for the form */
  align-items: center;
  justify-content: center;
  padding: 4px;
}

.form-row {
  display: flex;
  width: 100%;
  height: 100%; /* Ensure the row takes the full height */
  align-items: center;
}

.dropzone {
  flex: 0 0 45%; /* 45% of the width */
  height: 100%; /* Full height */
  padding: 1rem;
  border: 1.5px solid #012041;
  border-radius: 10px 0 0 10px;
  background: #fff;
  text-align: center;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dropzone-error {
  border-color: red;
}

.dropzone-text {
  font-size: 1rem;
  color: #666;
  margin: 0;
}

.remove-image-button {
  position: absolute;
  top: 0px;
  right: 0px;
  background: none;
  border: none;
  color: gray;
  font-size: 1.1rem;
  cursor: pointer;
  outline: none;
}

.remove-image-button:hover {
  color: #dc3545;
}

.image-preview-container {
  position: relative;
  display: inline-block;
}

.dropzone-image-preview {
  max-width: 100%;
  height: 50px;
  object-fit: cover;
  border-radius: 5px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
}

.upload-icon {
  margin-right: 0.5rem;
  color: #666;
}

.search-input {
  flex: 1;
  height: 100%; /* Full height */
  padding: 0.5rem;
  border: 1.5px solid #012041;
  border-radius: 5px;
  font-size: 1rem;
}

.search-bar-input{
  flex: 1;
  height: 100%; /* Full height */
  padding: 0.5rem;
  border: 2px solid #012041;
  border-radius: 5px;
  font-size: 1rem;
}

.input-error {
  border-color: red;
}

.search-submit-button {
  flex: 0 0 10%; /* 15% of the width */
  height: 100%; /* Full height */
  padding: 0.5rem;
  background-color: #012041;
  color: white;
  border: none;
  border-radius: 0 10px 10px 0;
  font-size: 1rem;
  cursor: pointer;
}

.search-submit-button:disabled {
  background-color: gray;
  cursor: not-allowed;
}

.search-submit-button:hover:enabled {
  background-color: #154d89;
}

.error-message-search {
  opacity: 0;
  transition: opacity 0.5s, max-height 0.5s; /* Added max-height for smooth transition */
  /* max-height: 0; Initially hidden */
  overflow: hidden; /* Hide overflowing content */
}

.error-message-search.show {
  opacity: 1;
  /* max-height: 100px; Adjust as needed to fit the content */
  overflow:visible;
}

.plan-expired-message, .query-expired-message {
  color: red;
  font-weight: bold;
  text-align: center;
  position: relative;
  display: flex;
  background-color: #fff;
  align-items: center;
  margin-top: 10px;
  border: 2px solid red;
  border-radius: 10px;
  height:65px;
  padding: 15px;
}

.plan-expired-message a, .query-expired-message a {
  color: red;
  text-decoration: underline;
}

.button-error-plan{
  background-color: red;
  border: 2px solid white;
  border-radius: 10px;
  margin: 5px;
  padding:0.6em;
  color:white;
}

.button-error-plan:hover, .button-error-plan:active{
  background-color: white;
  padding:0.6em;
  color:red;
  border-radius: 10px;
  border: 2px solid red;
  margin: 5px;
}

@media (max-width: 1000px) {
  .dropzone-text{
    font-size: 0.9em;
  }

  .search-input {
    font-size: 0.9em;
  }
}

@media (max-width: 600px) {
  .dropzone-text{
    font-size: 0.8em;
  }

  .search-input {
    font-size: 0.8em;
    width:50%;
  }

  .dropzone {
    padding:0.6em;
  }

  .upload-icon{
    display: none;
  }

}

@media (max-width: 380px) {
  .upload-icon{
    display: none;
  }

  .dropzone {
    padding:0.5em;
  }

  .dropzone-text{
    font-size: 0.65em;
  }

  .search-input {
    font-size: 0.65em;
    width:50%;
    min-width: 65px;
  }
}
