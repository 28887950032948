.advantages-background-container {
  background: linear-gradient(to bottom, white 0%, white 50%, #ddecef);
}

.advantages-container {
  width: 60%; /* Updated width */
  margin: 0 auto;
  padding: 20px;
  color: white;
  text-align: center;
}

.advantages-container h2 {
  color: #002955;
  font-size: 3em;
  margin: 50px auto;
  width : 80%;
  text-align: center;
}

.advantage-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.advantage-card {
  background-color: white;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: 15px;
  padding: 50px 60px;
  width: calc(50% - 40px); /* Adjusting width to fit 2 cards per row with margin */
  text-align: left;
  box-sizing: border-box; /* Ensures padding and border are included in the width */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.advantage-icon svg {
  width: 80px;
  height: 80px;
  margin-bottom: 20px;
}

.advantage-icon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.advantage-icon svg{
  color: #154d89;
}


.svg-gradient {
  width: 0;
  height: 0;
}

.advantage-content {
  width: 100%;
}

.advantage-content h3 {
  font-size: 23px;
  margin-bottom: 10px;
  font-weight: bold;
  color: #002955;
  text-align: center;
}

.advantage-content ul {
  list-style: none;
  padding: 0;
  text-align: left; /* Center-align the ul */
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 15px;
}

.advantage-content ul li {
  font-size: 16px;
  color: #666;
  margin-bottom: 10px;
  display: flex;
  align-items: left;
  justify-content: left;
  height: auto; /* Set fixed height for li */
}

.advantage-content ul li::before {
  content: '✔';
  color: #002955;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
  margin-right: 1.5em;
}


@media (max-width: 1400px) {

  .advantage-card {
    width: calc(85% - 2rem); /* Adjusting width to fit 2 cards per row with margin */
  }

}


/* Media Queries for responsive design */
@media (max-width: 1000px) {

  .advantages-container h2 {
    font-size: 2.2em;
    width : 80%;
  }

.advantages-container {
  width: 90%; /* Updated width */
  padding: 20px;
}

}


@media (max-width: 600px) {

  .advantages-container h2 {
    font-size: 1.8em;
    width : 80%;
  }

  .advantages-container {
    width: 90%; /* Updated width */
    padding: 20px;
  }

  .advantage-content ul {
    list-style: none;
    padding: 0;
    text-align: left; /* Center-align the ul */
    padding-top: 15px;
  }

  .advantage-content ul li {
    font-size: 13px;
    margin-bottom: 7px;
  }

  .advantage-icon svg {
    width: 60px;
    height: 60px;
    margin-bottom: 10px;
  }

  .advantage-content h3 {
    font-size: 20px;
    margin-bottom: 8px;
  }

}


@media (max-width: 480px) {

  .advantages-container {
    width: 100%; /* Updated width */
  }

  .advantage-card {
    background-color: white;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin: 10px;
    padding: 20px 30px;
  }

  .advantage-content ul li {
    font-size: 12px;
    height: auto; /* Set fixed height for li */
  }
}
