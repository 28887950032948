.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f4f4f4;
}

.login-form {
  background: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 480px; /* Increased by 20% from the original 400px */
}

.login-form h2 {
  margin-bottom: 1rem;
  text-align: center;
}

.message {
  color: green;
  margin-top: 20x;
  text-align: center;
}

.error {
  color: red;
  margin-top: 20px;
  text-align: center;
}


.form-group {
  margin-bottom: 1rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
}

.form-group input {
  width: 100%; /* Ensure input takes full width */
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
}

.login-button {
  width: 100%; /* Ensure button takes full width */
  padding: 0.75rem;
  border: none;
  border-radius: 4px;
  background-color: #002955;
  color: white;
  font-size: 1.1em;
  font-weight: 500;
  cursor: pointer;
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
}

.login-button:hover {
  background-color: #0056b3;
}

.login-delimiter{
  border: 1px solid #ddd;
  align-items: center;
  margin: 25px;
}

.login-trial{
  text-align: center;
  color:gray;
}

.login-button.disabled{
  background: #929292;
}
