.background-section {
    width: 100%;
    background: linear-gradient(to bottom, #ddecef 10%, #fff 100%);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px 0;
  }

  .info-band {
    width: 70%;
    background: #fff;
    padding: 40px 20px;
    text-align: center;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }

  .info-band h2 {
    font-size: 1.5em;
    color: #777;
    margin-bottom: 20px;
  }

  .info-items {
    display: flex;
    justify-content: space-around;
    width: 100%;
    align-items: flex-start;
    padding-top: 20px;
  }

  .info-item {
    display: flex;
    align-items: flex-start;
    max-width: 300px;
    text-align: left;
  }

  .info-item .icon {
    font-size: 2.1em;
    margin-right: 15px;
    color: #154d89;
    background-color: #ececec;
    border-radius: 10px;
    padding: 8px;
  }

  .info-item h3 {
    font-size: 1.2em;
    font-weight: bold;
    margin-bottom: 10px;
  }

  .info-item p {
    font-size: 1em;
    color: #555;
  }

  /* Media Queries for responsive design */
@media (max-width: 1000px) {
  .info-band {
    width: 70%;
    padding: 30px 15px;
  }

  .info-band h2 {
    font-size: 1.3em;
  }

  .info-items {
    flex-direction: column;
    align-items: center;
  }

  .info-item {
    margin-bottom: 20px;
    text-align: center;
    align-items: center;
  }

  .info-item .icon {
    margin-right: 10px;
    margin-bottom: 10px;
  }

  .info-item h3 {
    font-size: 1.1em;
  }

  .info-item p {
    font-size: 0.9em;
  }

}

@media (max-width: 600px) {
  .info-band h2 {
    font-size: 1.1em;
  }

  .info-item .icon {
    font-size: 1.8em;
  }

  .info-item h3 {
    font-size: 1em;
  }

  .info-item p {
    font-size: 0.8em;
  }
  .info-item svg{
    display: none;
  }
}
