.set-new-password-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background-color: #f9f9f9;
  }

.set-new-password-form {
    background-color: white;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
.set-new-password-button {
    width: 100%;
    padding: 0.75rem;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: white;
    font-size: 1rem;
    cursor: pointer;
  }

.set-new-password-button:hover {
    background-color: #0056b3;
  }

  .message, .error {
    margin-top: 1rem;
    color: green;
  }

  .error {
    color: red;
  }

  .forgot-password {
    margin-top: 1rem;
  }

  .forgot-password p {
    margin: 0;
  }

  .login-trial {
    margin-top: 1rem;
  }

  .login-trial p {
    margin: 0;
  }
