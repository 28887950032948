html {
  scroll-padding-top: 150px; /* Adjust the padding to your fixed top bar height */
}

.header {
    background: rgba(255, 255, 255, 0.2);
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    width: 100%;
    top: 0;
    z-index: 10;
  }

.navbar .nav-left a, .navbar .nav-right a {
  margin: 0 10px;
  text-decoration: none;
  color: black;
  background: rgba(255, 255, 255, 0);
  padding: 5px 10px;
  border-radius: 5px;
}

.intro-section {
  background-size: cover;
  background-position: center;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.intro-overlay {
  background: rgba(255, 255, 255, 0);
  padding: 40px;
  border-radius: 10px;
}

.intro-content {
  text-align: center;
}

.intro-content h1 {
  font-size: 2.5rem;
  color: black;
  margin-bottom: 20px;
}

.intro-content p {
  font-size: 1.2rem;
  color: black;
  margin-bottom: 20px;
}

.intro-content button {
  background: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 5px;
}

/* feature center section */
.features-section {
  display: flex;
  justify-content: space-around;
  padding: 50px 20px;
}

.features-section .card {
  flex: 1;
  padding: 20px;
  margin: 0 10px;
  background: #f0f0f0;
  text-align: center;
  border-radius: 10px;
}
