.pricing-section {
  background-color: #ddecef;
  width: 100%;
  padding: 150px 0;
}

.pricing-section-plateforme {
  background-color: #f3f3f3ea;
  width: 100%;
  padding-top: 50px;
  padding-bottom: 140px;
}

.pricing-subsection {
  width: 75%;
  margin: 0 auto;
  text-align: center;
}

.pricing-subsection-plateforme {
  width: 80%;
  margin: 0 auto;
  text-align: center;
}

.pricing-subsection h1 {
  font-size: 4em;
  margin-bottom: 60px;
  color: #002955;
}

.pricing-subsection-plateforme h1{
  font-size: 4em;
  margin-bottom: 60px;
  color: #002955;
}

.pricing-cards {
  display: flex;
  justify-content: space-between;
}

.pricing-card {
  background-color: white;
  border-radius: 10px;
  width: 30%;
  padding: 20px;
  text-align: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 500px;
}

.pricing-icon {
  font-size: 2.5em;
  margin-bottom: 10px;
  color: #002955;
}

.pricing-card h2 {
  color: black;
  margin-bottom: 10px;
}

.features-list {
  list-style: none;
  padding: 0;
  text-align: left; /* Center-align the ul */
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 15px;
  margin: auto;
}

.features-list li {
  font-size: 16px;
  font-weight: bold;
  color: #666;
  margin-bottom: 10px;
  display: flex;
  justify-content: left;
  align-items: center;
  height: auto; /* Set fixed height for li */
}

.features-list li::before {
  color: #002955;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
  margin-right: 1.5em;
}

.feature-list-logedin {
  text-align: center;
}

.feature-list-logedin p {
  font-style: italic;
}

.feature-list-logedin p span {
  font-style: normal;
  font-weight: bold;
  color: green;
}

.sub-li-plan{
  text-align: left;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  font: italic;
}

.green-icon {
  color: #218838;
  padding: 10px;
}

.red-icon {
  color: #e74c3c;
  padding: 10px;
}

.price-options {
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
}

.price-column {
  flex: 1;
  text-align: center;
  margin-right: 10px;
}

.price-column:last-child {
  margin-right: 0;
}

.price-column h3 {
  margin-bottom: 10px;
  padding-bottom: 5px;
  font-size: 1.3em;
  border-bottom: 2px solid #efefef;
}

.price-option {
  padding: 10px;
  border: 2px solid white;
  border-radius: 5px;
  cursor: pointer;
  background-color: #efefef;
  transition: background 0.3s, color 0.3s;
}

.price-option:hover {
  background: #002955;
  color: white;
}

.price-option.selected {
  border: 2px solid #002955;
  background-color: white;
  color:#002955;
}


.price-option.disabled {
  background: lightgray;
  color: gray;
  cursor: not-allowed;
}

.price-amount {
  font-size: 1.5em;
  font-weight: bold;
}

.button-container {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex: 1;
}

.pricing-cta-button {
  background: #002955;
  color: white;
  border: none;
  padding: 15px 30px;
  font-size: 1em;
  cursor: pointer;
  border-radius: 5px;
  width: 80%;
  margin: 0 auto;
}

.pricing-cta-button:hover {
  background: #218838;
}

.firm-presentation-cta-button.disabled-button {
  background: gray;
  cursor: not-allowed;
  border: none;
}

.firm-presentation-cta-button.disabled-button:hover{
 color:white;
}

.prefered{
  border: 4px solid red;
  /* box-shadow: 0 0 50px rgba(167, 57, 57, 0.952); */
}

@media (max-width: 1400px) {

  .features-list {
    list-style-type: none;
    padding: 0;
    text-align: center;
    height: 70px;
  }

  .features-list li {
    display: flex;
    align-items: center;
    margin-bottom: 4px;
    margin-left: 15px;
    font-size: 14px;
    font-weight: 600;
  }

  .price-column h3{
    font-size: 1.1em;
  }

  .price-option{
    padding: 5px;
  }

  .pricing-card h2{
    font-size: 1.6em;
  }

}


/* Media Queries for responsive design */
@media (max-width: 1000px) {
  .pricing-subsection,
  .pricing-subsection-plateforme {
    width: 90%;
  }

  .pricing-subsection h1,
  .pricing-subsection-plateforme h1 {
    font-size: 3em;
  }

  .pricing-cards {
    flex-direction: column;
    align-items: center;
  }

  .pricing-card {
    width: 80%;
    margin-bottom: 40px;
    height: auto;
    padding: 20px 40px;
  }

  .pricing-card h2 {
    font-size: 1.5em;
  }

  .features-list {
    height: auto;
  }

  .features-list li {
    font-size: 1em;
    margin-left: 0;
    justify-content: center;
  }

  .price-column h3 {
    font-size: 1.2em;
  }

  .price-amount {
    font-size: 1.2em;
  }

  .price-option{
    padding: 10px;
  }

  .pricing-card h2{
    font-size: 2em;
  }

}

@media (max-width: 600px) {
  .pricing-subsection h1,
  .pricing-subsection-plateforme h1 {
    font-size: 2em;
  }

  .pricing-icon {
    font-size: 2em;
  }

  .pricing-card h2 {
    font-size: 1.2em;
  }

  .features-list li {
    font-size: 0.9em;
  }

  .price-amount {
    font-size: 1em;
  }

  .pricing-cta-button {
    padding: 10px 20px;
    font-size: 0.9em;
    width: 100%;
  }

  .pricing-card {
    width: 80%;
    margin-bottom: 40px;
    height: auto;
    padding: 10px 20px;
  }

  .price-column h3 {
    font-size: 1em;
  }
}


@media (max-width: 480px) {
  .pricing-subsection h1,
  .pricing-subsection-plateforme h1 {
    font-size: 1.8em;
  }

  .pricing-icon {
    font-size: 1.8em;
  }

  .pricing-card h2 {
    font-size: 1em;
  }

  .features-list li {
    font-size: 0.8em;
  }

  .price-amount {
    font-size: 0.7em;
  }

  .pricing-cta-button {
    padding: 8px 15px;
    font-size: 0.8em;
    width: 100%;
  }

  .features-list{
    display:none;
  }

  .pricing-card {
    width: 90%;
    margin-bottom: 20px;
    height: auto;
    padding: 8%;
  }

  .price-column h3 {
    font-size: 0.9em;
  }
}
